$basic-green: #46eb7d;
$basic-blue: #46b4eba0;

$theme-cyan: #43e5f7;
$theme-darkbg: #162232;

$theme-darkcard: #1c334d;
$theme-dark-header: #3a5677;

:root {
  --primary-light: #8abdff;
  --primary: #6d5dfc;
  --primary-dark: #5b0eeb;

  --white: #ffffff;
  --black: #000000;
  --almostBlack: #101010;
  --greyLight-1: #e4ebf5;
  --greyLight-2: #c8d0e7;
  --greyLight-3: #bec8e4;
  --greyDark: #9baacf;
  --greyTheme: #162232;

  --lightmodetext: #8a92a5;
  --lightmodegrey: #ebecf0;
  --lightmodegrey2: #e6e7ec;
  --lightmodegrey3: #e3e4e9;
  --darkgrey: #8a92a5;
  --lightgrey: #ebecf0;
  --lightgrey2: #e6e7ec;
  --lightgrey3: #e3e4e9;
  --brightblue: #1758f1;
  --darkblue: #0732bf;
  --brightred: #ef1717;
  --darkred: #bf0b0b;
  --brightyellow: #ffd100;
  --darkyellow: #e0bb00;
  --brightgreen: #4ee51b;
  --darkgreen: #42c916;
  --brightpink: #ef17a4;
  --darkpink: #ce0e88;
  --brightpurple: #9517ef;
  --darkpurple: #720bbc;
  --sansfam: "Nunito", sans-serif;
}

$shadow: 0.3rem 0.3rem 0.6rem var(--greyLight-2),
  -0.2rem -0.2rem 0.5rem var(--white);
$inner-shadow: inset 0.2rem 0.2rem 0.5rem var(--greyLight-2),
  inset -0.2rem -0.2rem 0.5rem var(--white);



.noSelect {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

			 
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

@font-face {
  font-family: mainFont;
  src: url("/static/fonts/Assistant-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: mainLightFont;
  src: url("/static/fonts/Assistant-Light.ttf");
  font-display: swap;
}

html {
  overflow: hidden;
}

body {
  height: 100vh;
  position: relative;
  overscroll-behavior: contain;
  overflow: hidden;
  background-color: $theme-darkbg;
  font-family: mainFont;
  margin: 0px;
}

#root {
  height: 100%;
}
.container-fluid {
  height: 100%;
  padding-right: 0px;
  padding-left: 0px;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  background-color: $theme-darkbg;
}

.App-top {
  //display: flex;
  //flex-direction: row;
  height: 100%;
  width: 100%;
}


.App-logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 50%;
  margin: 0 auto;
  
}

.App-logo-top {

  height: auto;
  width: 6vh;
  margin-top: 1vh;
  pointer-events: none;
}


.spinner {
  animation: spin 1 1s ease-in-out;
}

.App-logo-bottom {
  //animation: App-logo-spin infinite 20s linear;
  height: 5vh;
  margin-top: 1vh;
  pointer-events: none;
}

.App-header .info {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.App-header .infoSegment {
  margin-top: 10px;
  width: 100%;
  align-items: center;
  font-size: 1.5vh;
  color: grayscale($color: #bbbbbb);
}

.App-header .infoSegment p {
  position: relative;
  top: 40%;
}

.App-header {
  background-color: $theme-darkbg;
  height: 20vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //font-size: calc(10px + 2vmin);
  color: white;
}

.AppControls * {
  font-size: 1rem;
  color: #bbbbbb;
  
}

.AppControls {
  display: flex;
  flex-direction: column;
  height: 40%;
  width: 100%;
  //background-color: rgb(35, 35, 35);
}




.countrySelector {
  position: relative;
  margin-top: 2px;
  //min-width: 3rem;
  margin: 0.5rem;
  
}

.countryMenu {
  //width: 10rem;
  cursor: pointer;  
  margin: auto;

  z-index: 1000;
}

.countryList {
  position: absolute;  
  display: none;
  flex-direction: column;
  text-align: left;
  padding-left: 1rem;
  
  padding-bottom: 1rem;
  padding-top: 1rem;
  //opacity: 90%;
  border-radius: 10px;
  background-color: $theme-darkbg;
  box-shadow: 5px 5px 4px rgba(0,0,0,0.5);
  width: 13rem;
  height: 9.5rem;
  overflow-y: scroll;
  
}

::-webkit-scrollbar {
  width: 0.6rem;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0.7rem rgba(0, 0, 0, 0.4); 
  border-radius: 10px;
  
  
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 0.3rem rgba(0,0,0,0.9); 
  //background-color: white;
  background-color: $theme-cyan;
}

.countryListItem {
  margin-bottom: 0.7rem;
  display: inline-block;
  flex-direction: row;
  align-items: left;
  justify-content: left;
}

.countryListItem:hover {
  //background-color: #263242;
}

.flag {
 position: relative; 
 height: 1.5rem;
}

.countryListItem .flag {
  position: absolute;
  right: 0.7rem;
  width: 1.2rem;
  height: 1.2rem;
}

.countryListItem .star {
  width: 1rem;
  height: 1rem;
  right: 2.5rem;
  position: absolute;
  filter:  saturate(0%) brightness(50%) contrast(100%);
  //display: none;
}

.countryListItem .star_active {
  width: 1rem;
  height: 1rem;
  right: 2.5rem;
  position: absolute;
  
  //display: none;
}


.typeSelector {
  position: relative;
  margin-top: 2px;
  width: 50%;
  min-width: 10rem;
  
}

.typeSelector .bg {
  background-color: $theme-darkbg;
  //border: solid 2px var(--white);
  width: 50%;
  min-width: 10rem;
  height: 2rem;
  border-radius: 1rem;

  box-shadow: -2px -2px 4px rgba(255,255,255,0.1),
  5px 5px 4px rgba(0, 0 ,0 ,0.1);
  //inset 3px 3px 4px 0px rgba(0, 0, 0, 0.3);
}

.countrySelector .txt {
  padding: 5px;
  text-align: left;
  padding-left: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(0);
}

.typeSelector .arrow {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10%;
  margin-top: 8%;
  opacity: 0.2;

  width: 0;
  height: 0;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;

  border-top: 0.5rem solid white;
}

.custom-range {
  width: 85%;
}

.custom-control-label::before {
  box-shadow: inset 3px 3px 4px 0px rgba(0, 0, 0, 0.22);
  border: none;
  background-color: $theme-darkbg;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: $theme-cyan;
  border: none;
}


.time-slider-container {
  height: 50%;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-left: 5vw;
}
.time-slider-container * {
  //margin-left: 0.5em;
  //margin-right: 0.5em;
}

.time-slider-container label {
  min-width: 5rem;
  margin-bottom: 0px;
  margin-left: 1rem;
  margin-right: 1rem;
}

.AppBodyContainer {
  height: 70vh;
  display: flex;  
}

.AppBody {
  background-color: $theme-darkbg;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.TimeScaleMark text {
  stroke-width: 0px;
  //animation: float 6s ease-in-out infinite;
  fill: #12e860;
}

.TimeScale {
  margin: auto;
  width: 90%;
  //background-color: red;
  display: flex;
  justify-content: space-between;
}

.TrendsContainer {
  //border: solid;
  border-radius: 15px;
  //border-color: red;
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 95%;
  max-width: 1400px;
  max-height: 1400px;
  padding: 5px;
  box-shadow: inset 6px 6px 10px 2px rgba(0, 0, 0, 0.15);
}

.TrendsSvg {
  width: 100%;

  height: 100%;
}

svg {
  font: bold 16px sans-serif;
}

.image-container {
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: 5vh;
}

@keyframes waves {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}

/*

.circle {
      
  box-shadow: .4rem .4rem .8rem var(--greyLight-2), 
  -.4rem -.4rem .8rem var(--white);
  background: linear-gradient(to bottom right, var(--greyLight-2) 0%, var(--white) 100%);
  animation: waves 4s linear infinite;

}
*/
.clickable {
  cursor: pointer;
}

.circle {
  grid-column: 2 / 3;
  grid-row: 4 / 6;
  width: 9rem;
  height: 100%;
  justify-self: center;
  border-radius: 1rem;
  display: grid;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;

  &__btn {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    width: 6rem;
    height: 6rem;
    display: flex;
    margin: 0.6rem;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 3.2rem;
    color: var(--primary);
    z-index: 300;
    background: var(--greyLight-1);
    box-shadow: $shadow;
    position: relative;
    &.shadow {
      box-shadow: $inner-shadow;
    }

    .play {
      position: absolute;
      opacity: 0;
      transition: all 0.2s linear;
      &.visibility {
        opacity: 1;
      }
    }
    .pause {
      position: absolute;
      transition: all 0.2s linear;
      &.visibility {
        opacity: 0;
      }
    }
  }

  &__back-1,
  &__back-2 {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    filter: blur(1px);
    z-index: 100;
  }

  &__back-1 {
    box-shadow: 0.4rem 0.4rem 0.8rem var(--greyLight-2),
      -0.4rem -0.4rem 0.8rem var(--white);
    background: linear-gradient(
      to bottom right,
      var(--greyLight-2) 0%,
      var(--white) 100%
    );
    animation: waves 4s linear infinite;

    &.paused {
      animation-play-state: paused;
    }
  }

  &__back-2 {
    box-shadow: 0.4rem 0.4rem 0.8rem var(--greyLight-2),
      -0.4rem -0.4rem 0.8rem var(--white);
    animation: waves 4s linear 2s infinite;

    &.paused {
      animation-play-state: paused;
    }
  }
}

.circle__btn {
  box-shadow: 0.3rem 0.3rem 0.6rem var(--almostBlack), -0.2rem -0.2rem 0.5rem $theme-darkbg;
  background: $theme-darkbg;
}

.circle__back-1 {
  box-shadow: 0.4rem 0.4rem 0.8rem var(--almostBlack), -0.4rem -0.4rem 0.8rem $theme-darkbg;
  background: linear-gradient(to bottom right, var(--almostBlack) 0%, $theme-darkbg 100%);

}

.circle__back-2 {
  box-shadow: .4rem 0.4rem 0.8rem var(--almostBlack), -0.4rem -0.4rem 0.8rem $theme-darkbg;
}

.image-cropper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
  background-color: $theme-darkbg;
}

#tooltipBackground {
  display: flex;
  position: absolute;
  background: #00000070;
  border: 1px solid black;
  border-radius: 5px;
  justify-content: center;
  padding: 5px;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 1;
}

#countryListBackground
{
  position: absolute;
  background: #00000030;
  width: 100vw;
  height: 100vh;
  top:0;
  left:0;
  z-index: 1;
  display: none;
}


.popupScreen {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: none;
  align-items: center;
  align-content: center;
  text-align: center;
  justify-content: center;

  
}
#helpScreen {
  display: block;
}

#help {
  height: 60%;
  display: flex;
  flex-direction: column;

}
.logoInfoBox {
  transform: scale(0.55,0.55)  translate(-90px,-90px) ;
}

.popupBox {
  background-color: $theme-darkbg;
  color: white;
  align-items: center;
  justify-content: center;

  position: fixed;
  background: #162232;
  //border: 1px solid black;
  border-radius: 20px;
  padding: 5px;
    
  width: 90%;
  max-width: 720px;

  max-height: 580px;
  
  
  bottom: 15%;
  
  box-shadow: 7px 7px 4px 0px rgba(0, 0, 0, 0.35);

  //display: none;
}

.popupBox .closeButton {
  font-size: 1.8rem;
  font-family: OCR A Std, monospace;
  color: black;
  filter: invert(86%) sepia(23%) saturate(2044%) hue-rotate(151deg) brightness(97%) contrast(100%);
  right: 1em;
  top: 2vh;
  position: absolute;
  //margin: 0.7em;
  cursor: pointer;
}

.popupBox .info-title {
  font-size: 1.4rem;
  margin: 15px;
  //color: grayscale($color: #bbbbbb);
}

.popupBox a {
  //font-size: 1.4em;
  //color: grayscale($color: #bbbbbb);
  text-decoration: underline;
}



.popupBox .description {
  font-size: 1.1rem;
  //color: grayscale($color: #bbbbbb);
  font-family: mainLightFont;
}

.popupBox .helpDescription {
  font-size: 1rem;
  
  //color: grayscale($color: #bbbbbb);
  font-family: mainLightFont;
  text-align: left;
  padding: 15px;
  padding-right: 35px;

  overflow-y: scroll;
  height:90%;


  .info-title {
    font-size: 1.5rem;
  }
}

.popupBox .helpDescription li {
  margin-bottom: 5px;
}

.popupBox .helpDescription h1 {
  //text-decoration: underline;
  font-size: 3vh;
}

.popupBox * {
  font-size: 1em;
  //color: grayscale($color: #bbbbbb);
  font-family: mainFont;
}



@keyframes pulse {
  0% {
    transform: translatey(0px); // scale(1);
  }

  50% {
    transform: translatey(-1px); // scale(1.1);
  }
  100% {
    transform: translatey(0px); // scale(1);
  }
}

.TrendBubbleVisited {
  filter: grayscale(100%);
}

.TrendBubbleWithText {
  animation: pulse 6s ease-in-out infinite;
}

.TrendBubble {
  stroke: rgba(50, 50, 50, 0.4);
  stroke-width: 0.3;
  fill: $basic-blue;
  //animation: float 6s ease-in-out infinite;
}

.TrendBubbleWithText text tspan {
  //font-family: Arial, Helvetica, sans-serif;
  //font-family: rubikFont;

  stroke-width: 0px;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  //fill: rgb(255, 255, 255);
  fill: $theme-darkbg;
  display: flex;
}

.TrendBubble:hover {
  stroke: rgb(40, 200, 250);
}

.BubbleHref {
    -webkit-tap-highlight-color: transparent;
}

.antispam {
  display: none;
}

.rangeslider {
  cursor: pointer;
}

/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="range"] {
    overflow: hidden;
    width: 90%;
    -webkit-appearance: none;
    background-color: $theme-darkbg;
    box-shadow: inset 3px 3px 4px 0px rgba(0, 0, 0, 0.3);
    border-radius: 5em;
    height: 0.75rem
  }

  input[type="range"]::-webkit-slider-runnable-track {
    height: 1rem;
    -webkit-appearance: none;
    color: #13bba4;
    margin-top: -1px;

    //box-shadow: -100vw 0 0 100vw #f3e5f7;
  }

  input[type="range"]::-webkit-slider-thumb {
    width: 1rem;

    -webkit-appearance: none;
    height: 1rem;
    border-radius: 50%;
    //margin-top: -5px;
    cursor: pointer;

    background: #ffffff;
    //margin-left: 0px;
    box-shadow: -100vw 10vw 1vw 100vw $theme-cyan;

    // $theme-cyan
  }
}
/** FF*/
input[type="range"]::-moz-range-progress {
  background-color: $theme-cyan;
}
input[type="range"]::-moz-range-track {
  background-color: rgb(41, 43, 59);
}
/* IE*/
input[type="range"]::-ms-fill-lower {
  background-color: $theme-cyan;
}
input[type="range"]::-ms-fill-upper {
  background-color: #9a905d;
}

.footer-applink {
  text-align: left;
  margin-left: 5%;
  flex-direction: column-reverse;;
  position: absolute;
}
.applink-icon {
  width: 25vw;
  max-width: 200px;
  height: auto;
}

#TmpAppAnnouncement {
  margin:10px;
  position: absolute;
  background-color: #162232;
  z-index: 1;
}


.footer-container {
  //background-color: red;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  justify-items: center;

  height: 10vh;
  
}

.footer-top {
  display: flex;
  flex-direction: row;
  width: 100%;
  //margin-top: 1rem;
  margin-left: 1rem;
  margin-top: 1rem;
}

.footer-bottom {
  height:  5rem;
}


.footer-contact {
  border-radius: 1em;
  //border: solid;
  border-width: thin;
  padding: 0.3em;
  font-size: small;
  //width: 1em;
  line-height: 2.5em;
  margin: 1em;

  color: rgb(74, 217, 197);
  box-shadow: -3px -3px 4px 0px rgba(150, 150, 150, 0.05),
    3px 3px 4px 2px rgba(0, 0, 0, 0.15);

    
}

.contact-container {
  text-align: right;
  width: 50%;
  margin-right: 3rem;
}

.contact-link {
  text-decoration: none;
  //margin-right: 1rem;
  width: 10rem;
  cursor: pointer;
}


.info-container {
  //width: 10%;
}


.footer-powered {
  color: #bbbbbb;
  width: 100%;
  //height: 10em;
  font-size: 12px;
  text-align: center;
  
  
}


.external-websites-icons-container {
  //position: fixed;
  text-align: left;
  width: 50%;
  margin-left: 2rem;
}

.global_flag {
  filter: invert(86%) sepia(23%) saturate(2044%) hue-rotate(151deg) brightness(97%) contrast(100%);
}


.btn-vector-icon {
  filter: invert(86%) sepia(23%) saturate(2044%) hue-rotate(151deg) brightness(97%) contrast(100%);
  width: 5vw;
  max-width: 1.6rem;
  margin-left: 0.3rem;
  margin-right: 0.1rem;
}

.controlCenter .btn-vector-icon {
  
  width: 10vw;
  max-width: 1.75rem;
  margin-left: 0.3rem;
  margin-right: 0.1rem;
}

.grayed-icon {
  filter: invert(73%) sepia(1%) saturate(0%) hue-rotate(8deg) brightness(95%) contrast(88%);
}

.shareButton {
  left: 1rem;
  top: 1rem;
  margin: 0.5rem;
  position: absolute;
  display: none;
  //opacity:0;
  cursor: pointer;
}

.shareLink {
    display: block;
    color: white;
    height: 2rem;
    word-wrap: break-word;
    position: relative;
    top: 2rem;
    align-items: center;
    align-content: center;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 3.5rem;
    margin-left: 1rem;
    margin-right: 1rem;

}

.controlContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.controlCenter {
  width: 90%;
  max-width: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchContainer { 
  margin: auto;
  display: flex;
  width: 60%;
  flex-direction: row-reverse;
}


.searchTextBox {
  background-color: $theme-darkbg;
  border-radius: 20px;  
  outline: 0;
  //width: 10vw;
  width: 100%;
  min-width: 4rem;  
  max-width: 8rem;
  box-shadow: inset 3px 3px 4px 1px rgba(0, 0, 0, 0.42);
  -webkit-box-shadow: -2px -2px 4px 0px rgba(0,0,0, 0.42);
  border-width: 0;

  padding: 7.5px;

}

// Safari (9+) hack
@supports (-webkit-hyphens:none) {
    .searchTextBox { border-width: 1px; }

}


.searchTextBox:focus {
  background-color: $theme-darkbg;
}


.articleSourceLink {
  color:  #219bc4;
}

#bmc-wbtn{
  bottom: 15px;
}

#bmc-wbtn + div{
  bottom:15px;
}

.trendListItem .shareButton {
  top: 0.8rem;
  left: 0.8rem;
  z-index: 5;
}

.trendListItem .popupScreen {
  margin-bottom: 1rem;
}

.trendListItem .popupScreen .popupBox {
  background-color: #22334d;
}

.listCard {
    display:flex;
    flex-direction: column;
   
}

.listCardUpper {
  display: flex;
  flex-direction: row;
  align-items: center;
  scale: 0.85;
  padding-top: 1rem;
}

.listCardUpper .image-container {
  width: 5rem;
  margin-top: inherit;
  scale:0.8;
}


.listCardUpper .info-title {
  scale: 0.85;
  width: 20rem;
}

.listCardUpper .listCardTraffic {
  scale: 0.9;
  width: 10rem;
  display: flex;
  flex-direction: column;
}
.listCardUpper .listCardTraffic span {
    display: inline-block;
    width: max-content;
  }

.listCardUpper .listCardDate {
  scale: 0.9;
  position: fixed;
  top: 0;
  right: 0;
  font-size: 1.2rem;
}

.listCardLower {
    text-align: left;
    padding-left: 2rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    justify-content: center;
    align-content: center;

}

.trendsListViewHeader {
    //height: 10%;
  border-radius: 1rem;
  //box-shadow: 5px 5px 4px rgba(0,0,0,0.5);
  margin: 0.2rem;
  color: white;
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  //border: solid;


}
.trendsListViewHeader div {
 background-color: rgba(0, 0 ,0 ,0); 
 color: $theme-cyan;
 min-width: 4.5rem;
}

.trendsListView {
display: flex;
flex-direction: column;
//height: 90%;
padding: 1rem;
overflow-y: scroll;
overflow-x: hidden;
}

.trendListItemVisited {
  filter: grayscale(100%);

}

.trendListItem .popupScreen {
    display: block;
    position: relative;

    display: block;
    position: relative;
    width: auto;
    height: auto;

}
.trendListItem .popupBox {
    display: block;
    position: relative;
    width: 100%;
    max-width: initial;
    background-color: #121c2b;
    margin-bottom: 5px;
    box-shadow: inherit;
}
// .trendListItem span {
//   display: grid;
//   top: 50%;
//   justify-content: left;
//   align-items: center;
//   height: 100%;
//   font-size: 1rem;
//   margin-left: 0.5rem;
// }


.trendListItemTitle {
  margin: 0.2rem;
  width: 33%;
  height: 100%;
  font-size: 1.2rem;
}

.trendListItemTitle span {
    justify-content: center;
}

.trendListItem .info-title {
    margin: initial;
}

.trendListItemDesc {
  margin: 0.2rem;
  //width: 50%;
  height: 100%;
  font-size: 1.2rem;
}

.trendListItemImg {
    justify-content: center;
    display: flex;
}

.trendListItemDate {
  margin: 0.2rem;
  width: 33%;
  min-width: 4rem;
  height: 100%;
}

.trendListItemTraffic {
  margin: 0.2rem;
  width: 33%;
  min-width: 2rem;
  //max-width: 10rem;
  height: 100%; 

}

.trendListItemTrafficContainer meter {
    max-width: 10rem;
}

.trendListItemTrafficContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;

}



.changeViewContainer {
  //width: 10%;  
  display: flex;
  flex-direction: row;
  margin: 0.5rem;
}


.changeViewContainer img {
  float: right;
  cursor: pointer;
  
}

.listViewHeadersDivider {
  border-style: solid;
  border-top: none;
  border-width: 0.1rem;
  border-color: $theme-cyan;
}

meter::-webkit-meter-optimum-value {
  //background: red;
      box-shadow: 0 5px 5px -5px #999 inset;
      background-image: linear-gradient(
        $theme-cyan 5%,
        $theme-cyan 100%
      );
      background-size: 100% 100%;
}

.trendListItemTrafficContainer meter {
  height: 1rem;
  width: 100%;
}
